import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import donate from "../assets/donate.jpeg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Import useAuth
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://www.ev-center.com/english.html"
        target="_blank"
      >
        Engineering Village
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function LogIn() {
  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from AuthContext
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    // Define the API URL
    const apiUrl =
      "https://cloud.ev-center.com/zakatseeb/be_light/api/getUsers.php";

    // Create a request payload
    const data = new URLSearchParams();
    data.append("username", userName);
    data.append("password", password);

    try {
      // Send a POST request to the API
      const response = await axios.post(apiUrl, data);

      // Check if the API returned a user object (customize this check based on your API response)
      if (response.data && response.data.username) {
        // Successful login, call the login function from AuthContext
        login(response, userName);
        navigate("/events");
      } else {
        // Invalid credentials, you can handle this case as needed
        console.error("Invalid credentials");
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${donate})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#ff8080" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              تسجيل الدخول{" "}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }} dir="rtl">
              <TextField
                margin="normal"
                required
                fullWidth
                value={userName}
                onChange={(event) => setUserName(event?.target.value)}
                placeholder="اسم المستخدم"
                dir="rtl"
                autoFocus
                style={{ textAlign: "left", background: "#ffff" }}
              />
              <TextField
                margin="normal"
                required
                type="password"
                value={password}
                onChange={(event) => setPassword(event?.target.value)}
                fullWidth
                id="email"
                placeholder=" رمز الدخول"
                dir="rtl"
                autoFocus
                style={{ textAlign: "left", background: "#ffff" }}
              />

              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: "16px",
                  background: "#01A66F",
                  ":focus": { background: "#BDD99E" },
                  ":hover": { background: "#01A66F" },
                }}
                onClick={handleLogin}
              >
                تسجيل الدخول{" "}
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
