import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom";

// Translation function
const translate = (segment) => {
  switch (segment) {
    case "events":
      return "الفعاليات";
    case "edit":
      return "تعديل";
    case "devices":
      return "الأجهزة";
    case "newEvent":
      return "فعالية جديدة";
    // Add translations for other segments as needed
    default:
      return segment;
  }
};

export default function CustomBreadcrumbs() {
  const location = useLocation();

  // Split the pathname into segments to create breadcrumb items
  const pathSegments = location.pathname.split("/").filter(Boolean);

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ transform: "scaleX(-1)" }} fontSize="small" />
      }
      aria-label="breadcrumb"
      sx={{
        textTransform: "uppercase",
        fontSize: "12px",
        fontFamily: "Noto Kufi Arabic",
        color: "grey",
        padding: "8px 16px",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
        backgroundColor: "white",
        textAlign: "right", // Align the text to the right for RTL
        direction: "rtl", // Set the text direction to RTL
      }}
    >
      {pathSegments.map((segment, index) => {
        const isCurrentPage = index === pathSegments.length - 1;
        const translatedSegment = translate(segment);

        return (
          <span key={segment}>
            {isCurrentPage ? (
              // Render the current page segment as plain text
              <Typography color="#ffc266" sx={{ fontWeight: "bold" }}>
                {translatedSegment}
              </Typography>
            ) : (
              // Render the link for other segments
              <Link
                href={`/${pathSegments.slice(0, index + 1).join("/")}`}
                sx={{
                  fontSize: "15px",
                  textDecoration: "none",
                  color: "grey",
                  // Grey color for clickable crumbs
                  "&:hover": { color: "grey", textDecoration: "underline" },
                }}
              >
                {translatedSegment}
              </Link>
            )}
          </span>
        );
      })}
    </Breadcrumbs>
  );
}
