import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// export const getUser = () => {
//   const storedUser = localStorage.getItem("user");

//   return storedUser;
// };

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user"));

  const login = (response, username) => {
    // Simulate successful authentication (replace with your own logic)
    if (response?.data) {
      localStorage.setItem("user", JSON.stringify({ username: username }));
      const storedUser = localStorage.getItem("user");
      console.log("userrrr : " + storedUser);
      setUser(storedUser);
    } else {
      console.log(username, "username", response, "name");
      throw new Error("Invalid credentials");
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
