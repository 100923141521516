import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/LogIn"; // Import the Login component
import "./App.css";
import { useAuth } from "./AuthContext"; // Import your AuthContext

function App() {
  const { user } = useAuth();
  function ProtectedRoute({ element, ...rest }) {
    console.log(user, "user");
    return user ? React.cloneElement(element, rest) : <Navigate to="/login" />;
  }

  return (
    <div>
      <Routes>
        {/* Define the login route */}
        <Route path="login" element={<Login />} />

        {/* Define a protected route */}
        <Route
          path="/*"
          element={<ProtectedRoute element={<Home user={user} />} />}
        />

        {/* Redirect to /login if no matching route */}
        {/* <Route path="/*" element={<Navigate to="/login" />} /> */}
      </Routes>
    </div>
  );
}

export default App;
