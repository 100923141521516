import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import zakahImage from "../assets/zakah.jpeg";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../AuthContext"; // Import useAuth

export default function Menu() {
  const [value, setValue] = useState(0);
  const { logout } = useAuth(); // Use the login function from AuthContext

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: "0",
        width: "100%",
        background: "linear-gradient(45deg, #BDD99E, #01A66F)",
        position: "relative", // Add position relative to the parent
      }}
    >
      <Tabs
        value={value}
        // onChange={handleChange}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
        textColor="inherit"
        indicatorColor="none"
        sx={{
          height: "50px",
          zIndex: 1,
          marginBottom: "5px",
        }} // Ensure Tabs are on top
        dir="rtl"
      >
        <Tab
          label="تسجيل الخروج"
          onClick={logout}
          iconPosition="start"
          sx={{ color: "#ffff", marginBottom: "5px" }}
        />
      </Tabs>

      <img
        src={zakahImage}
        alt="Zakah"
        width={"250px"}
        height={"200px"}
        style={{
          position: "absolute", // Position the image absolutely
          top: "0", // Adjust the top position as needed
          left: "100px", // Adjust the right position as needed
          zIndex: 2, // Ensure the image is on top
          borderRadius: "0 0 0 100px",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
        }}
      />
    </Box>
  );
}
